@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

@media (max-width: 1023px) {
  .secondary-header {
    @apply block;
  }
}

@media (max-width: 767px) {
  /* .hero-title div span {
    @apply block;
    @apply text-center;
    @apply my-10;
  } */
}
