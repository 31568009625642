.content-wrapper a {
  @apply text-blue-500;
}

.content-wrapper ul {
  @apply ml-6 list-disc;
}

.content-wrapper li {
  @apply list-disc;
}

.content-wrapper span {
  @apply inline-block;
  @apply font-turbinado-pro;
  @apply text-[6.7rem];
  @apply align-middle;
  @apply mb-2;
}

@media (max-width: 767px) {
  .content-wrapper span {
    @apply text-[4.7rem];
    @apply leading-tight;
  }
}

.content-wrapper img {
  @apply inline-block;
  @apply align-middle;
  @apply mb-2;
  @apply ml-5;
}
